.App {
  position: absolute;
  top: 0;
  background-color: #0f172a;
  /* height: auto; */
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  color: white;
  display: flex;
  gap: 1rem;
  /* min-width: 1024px; */
  max-width: 1280px;
  align-self: center;
  justify-content: flex-end;
  display: block;
}

.sectionsDiv {
  /* margin-top: 4rem; */
}

@import "typeface-inter";

@media (max-width: 1280px) {
  .App {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .leftSide {
    margin-left: -4rem !important;
  }
  .rightSide {
    padding-right: 3rem;
    margin-left: 21rem !important;
  }
  /* Your CSS styles for screens less than 1024px wide go here */
}

@media (min-width: 1281px) {
  .rightSide {
    margin-left: 35rem !important;
  }
  .backgroundp {
    max-width: 30rem !important;
  }
  .leftSide {
    margin-left: 3rem;
  }
}

.AustinSmith {
  text-decoration: none !important;
  line-height: 2.5rem;
  letter-spacing: -0.025em;
  color: #e2e8f0;
}

.AustinSmith:hover {
  cursor: pointer;
}

.leftSide,
.rightSide {
  width: 50%;
}

.leftSide {
  /* position: absolute; */
  /* position: fixed; */
  position: sticky;
  top: 6rem;
  /* padding-left: 6rem; */
  display: block;
  width: 340px;
  margin-right: 0;
}

.rightSide {
  margin-left: 25rem;
  position: relative;
  top: -26rem;
  padding-top: 6rem;
}

#root {
  height: 100vh;
  width: 100vw;
  background-color: #0f172a;
  display: flex;
  justify-content: center;
}

body {
  overflow-x: hidden;
}

* {
  font-family: "Inter", sans-serif;
}

.NameHeader1 {
  margin: 0;
  font-size: 3rem;
  font-weight: 700;
  color: #e2e8f0;
}

.cursor-spotlight {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
  background: radial-gradient(
    300px at 50% 50%,
    rgba(29, 78, 216, 0.15),
    transparent 80%
  );
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  opacity: 0;
  mix-blend-mode: screen;
  will-change: transform, opacity;
  pointer-events: none;
}

.h2Softwareengineer {
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #dde3eb;
}

.pdescription {
  max-width: 20rem;
  color: #8492a7;
  line-height: 1.5;
  font-weight: 500;
}

.backgroundp {
  line-height: 1.625;
  color: #8492a7;
  max-width: 30rem;
  margin-top: 0;
  font-weight: 500;
}

html {
  scroll-behavior: smooth;
  background-color: #0f172a;
}

#experience {
  padding-top: 6rem;
}

.nav-indicator {
  margin-top: 2rem;
  display: inline-block;
  width: 30px; /* Initial width */
  height: 1px;
  margin-bottom: 5px;
  background-color: #ccc; /* Initial color */
  margin-right: 12px; /* Spacing between indicator and text */
  transition: width 0.1s ease-in-out, background-color 0.3s ease-in-out;
}

.nav-text {
  letter-spacing: 0.075em;
  font-size: 0.75rem;
  font-weight: 600;
  color: #8492a7;
  transition: margin-left 0.1s ease-in-out; /* Transition property */
}

.nav-text:hover {
  letter-spacing: 0.075em;
  font-size: 0.75rem;
  font-weight: 600;
  color: #e2e8f0;
  transition: margin-left 0.1s ease-in-out; /* Transition property */
}

/* Hover styles */
a:hover .nav-indicator {
  width: 62px; /* Expanded width on hover */
  background-color: #e2e8f0;
}

a {
  text-decoration: none !important;
}

li {
  list-style: none;
}

.fa-github,
.fa-linkedin-in,
.fa-instagram,
.fa-twitter {
  position: fixed;
  bottom: 10vh;
  font-size: 1.5rem;
  color: #8492a7;
  transition: color ease-in-out 0.2s;
}
.fa-github:hover,
.fa-linkedin-in:hover,
.fa-instagram:hover,
.fa-twitter:hover {
  cursor: pointer;
  color: #e2e8f0;
}

.fa-instagram {
  margin-left: 2.75rem;
}
.fa-linkedin-in {
  margin-left: 5.5rem;
}

.resumeText {
  letter-spacing: 0.075em;
  font-size: 1rem;
  font-weight: 600;
  color: #e2e8f0;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.resumeText:hover {
  color: #44c5c1;
}

.resumeDOwnloadContainer {
  /* position: fixed;
  bottom: 17vh; */
  font-size: 1rem;
  color: #8492a7;
}

.fa-arrow-right {
  margin-left: 0.5rem;
}

.resumeDOwnloadContainer:hover {
  cursor: pointer;
  color: #e2e8f0;
}

.fa-download {
  font-size: 1rem;
}

.experienceDiv {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  transition: background-color 0.2s ease, color 0.2s ease;

  width: 100%;
  max-width: 30rem;
  position: relative;
  left: -2rem;
  margin-bottom: 3rem;
}

.experienceDiv:hover .experienceDesc {
  color: #44c5c1; /* Change subDiv background color on parent hover */
}

.experienceDiv:hover {
  cursor: pointer;
  background-color: rgba(20, 32, 58, 0.795); /* Background color */
  border-radius: 0.25rem; /* Rounded corners */
  color: rgb(226, 232, 240); /* Text color */
}

.experienceDesc {
  color: #e2e8f0;
  /* margin-top: -5rem; */
  transition: color ease-in-out 0.2s;
}

#experience,
#projects {
  padding-top: 6rem;
}

.experiencedescunder {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #8492a7;
  font-weight: 500;
  line-height: 1.5;
}

.experienceDate {
  font-size: 0.75rem;
  color: #8492a7;
  font-weight: 500;
  max-width: 5rem;
  /* margin-top: -5rem; */
}

.backgroundp2 {
  margin-top: 15rem;
}

.text-teal-300 {
  --tw-text-opacity: 1;
  color: rgb(94 234 212 / var(--tw-text-opacity));
  line-height: 1.25rem;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: rgba(45, 212, 191, 0.1);
  border-radius: 9999px;
  align-items: center;
}

.technologiesDiv {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.froobsbooksimage {
  background-image: url("./assets/froobsbookspreview.png");
  background-size: cover; /* or contain, or specific dimensions */
  height: 130px;
  width: 71px;
  background-position: center center;

  background-repeat: no-repeat;
}

.t6BrieferImage {
  background-image: url("./assets/t6brieferPrieview.png");
  background-size: cover; /* or contain, or specific dimensions */
  height: 120px;
  width: 67px;
  background-position: center center;

  background-repeat: no-repeat;
}

.froobleImage {
  background-image: url("./assets/frooblepreview.png");
  background-size: cover; /* or contain, or specific dimensions */
  height: 130px;
  width: 78px;
  background-position: center center;

  background-repeat: no-repeat;
}

.floatBookImage {
  background-image: url("./assets/floatbookpreview.png");
  background-size: cover; /* or contain, or specific dimensions */
  height: 130px;
  width: 86px;
  background-position: center center;

  background-repeat: no-repeat;
}

.stockBotImage {
  background-image: url("./assets/stocktradingbotpreview.png");
  background-size: cover; /* or contain, or specific dimensions */
  height: 130px;
  width: 79px;
  background-position: center center;

  background-repeat: no-repeat;
}

.leetcodeCloneImage {
  background-image: url("./assets/leetcodeClonePreview.PNG");
  background-size: cover; /* or contain, or specific dimensions */
  height: 50px;
  width: 100px;
  background-position: center center;

  background-repeat: no-repeat;
}

.experienceText {
  display: none;
}

@media (max-width: 830px) {
  .App {
    padding-top: 1rem;
    padding-right: 0px !important;
    padding-left: 0rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .leftSide {
    /* position: absolute; */
    position: relative;
    top: 2rem;
    margin-right: 0px !important;
    margin-left: 0px !important;
    /* width: 100vw; */
    padding-left: 5vw !important;
    padding-right: 0px !important;
    width: 80vw !important;
    overflow-x: hidden !important;
  }
  .rightSide {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    padding-left: 5vw;
    padding-right: 0px !important;
    position: relative;
    top: 4rem;
    padding-top: 0rem !important;
    margin-top: 0px !important;
    width: 80vw !important;
    overflow-x: hidden !important;
  }
  .fa-github,
  .fa-linkedin-in,
  .fa-instagram,
  .fa-twitter {
    position: relative;
    font-size: 1.5rem;
    color: #8492a7;
    transition: color ease-in-out 0.2s;
    top: 0rem;
    margin-bottom: 5rem;
  }
  .fa-instagram {
    margin-left: 1.5rem;
  }
  .fa-linkedin-in {
    margin-left: 1.5rem;
  }
  .fa-twitter {
    margin-left: 1.5rem;
  }
  .sectionsDiv {
    position: relative;
  }
  .experienceText {
    display: block;
    letter-spacing: 0.08rem;
    color: #e2e8f0;
    font-size: 0.85em;
    margin-bottom: 2rem;
  }
  .sectionsDiv {
    display: none;
  }
  .cursor-spotlight {
    display: none;
  }
  .AustinSmith {
    font-size: 0.75em;
  }
}
